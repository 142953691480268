export default {
  async selectFichero (Vue, idfichero) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.fichero)
      .innerJoin(
        tables.tfichero,
        tables.fichero.idtfichero.eq(tables.tfichero.idtfichero)
      )
      .where(tables.fichero.idfichero.eq(idfichero))
      .exec())[0]
  },
}
