<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
      <b10-file-gallery
        class="pa-3"
        :images="[formattedItem]"
        data-field="fichero.data"
        type-field="fichero.type"
        filename-field="fichero.nombre_fichero"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './FicheroViewData'
import { extractFileName } from '@/utils/files'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesOfflineFicheroView'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    editRoute: {
      type: String,
      default: 'offline__fichero-edit'
    },
  },
  data () {
    return {
      pageStoreName,
      moreInfoRows: [
        { name: 'fichero.nombre_fichero', label: 'Nombre' },
        { name: 'tfichero.descripcion', label: 'Tipo de fichero' },
        { name: 'fichero.fcreacion', label: 'Creación', filter: this.$options.filters.shortDateTime },
        { name: 'fichero.observaciones', label: 'Observaciones' },
        { name: 'fichero.carpeta', label: 'Carpeta' },
      ],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        edit: {
          title: 'Modificar',
          visible: true,
          icon: 'edit',
        },
      },
    }
  },
  computed: {
    readonly: get(`${pageStoreName}/readonly`),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.fichero.idfichero
        item.title = item.fichero.nombre_fichero
        item.subtitle = item.tfichero.descripcion
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = extractFileName(this.item.dataset.fichero.nombre_fichero)
      this.toolbarOptions.edit.visible = !this.readonly && this.hasEditPerm(this.$route.meta.permission.idobjeto)
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectFichero(this, this.routeParams.idfichero)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.edit) {
        this.$appRouter.push({
          name: this.editRoute,
          params: {
            idfichero: this.routeParams.idfichero,
          }
        })
      }
    },
  }
}
</script>
